<template>
  <div>

    <div class="box">
      <inhead></inhead>
      <div style="height: 80px"></div>
      <div id="detail-blog">
        <!--        <h1></h1>-->
        <hr class="cc" noshade color="azure">


        <div style="border-bottom: 2px solid rgba(128,128,128,0.38) ;box-shadow: 1px 1px 2px 1px rgba(128,128,128,0.34); width: 100%;height: 120px;display: flex;margin-top: 10px" v-for="(item,i) in article.pageData" :key="i">

          <div style="border-radius: 10px;box-sizing: border-box;box-shadow: 1px 1px 3px 2px rgba(128,128,128,0.25); width: 170px;height: 95%;display:flex;justify-content: center;align-items: center;margin-left: 5px;margin-top: 3px"><img  class="posts_img" @click="goArticle(item.id)" :src="item.posts_image" width="100%" height="100%" alt="" style="border-radius: 10px"></div>
          <div style="display: flex;width: 80%;margin-left: 20px;height: 90%;flex-wrap: wrap;">
            <div style="width: 95%;height: 30px;display: flex;align-items: center;">
              <!--           <a-tag  style="width: 11%;text-align: center;height: 30px;display: flex;justify-content: center;align-items: center"><p style="font-weight: 400;font-size: 8px">123</p></a-tag>-->

              <div style="width: 100%;display: flex;align-items: center;height: 30px;font-size: 23px;font-weight:600; "><p class="post_name" style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden" @click="goArticle(item.id)">{{item.posts_name}}</p></div>

            </div>

            <div style="width: 95%;text-overflow: ellipsis;overflow: hidden;height: 37px;" class="posts_body" @click="goArticle(item.id)">{{item.text}}</div>

            <div style="width: 120%;margin-top: 25px;">

              <p style="float: left; " >{{ item.update_time }}</p>

              <a-tag style="float: right;" class="readArticle" @click="goArticle(item.id)">阅读全文</a-tag>

            </div>
          </div>

        </div>

        <hr class="cc" noshade color="azure">
        <div style="display: flex;width: 100%;justify-content: center;align-items: center">

          <a-pagination v-model="current" :total="parseInt(article.count)" :defaultPageSize="7" show-less-items  @change="pageCurrentMethod(current)" class="btnn"/>
        </div>
      </div>
      <infoot v-if="!isMobile" style="margin-top: 62.7vh"></infoot>
    </div>
    <infoot v-if="isMobile"></infoot>
  </div>
</template>

<script>
import inhead from '../inhead'
import infoot from "../infoot";
export default {
  components:{
    infoot,
    inhead
  },
  name: "ArticleView",
  data(){
    return{
      icon_pc:require('@/assets/icon_pc.png'),
      article:{},

      current:1,

      isMobile:'',

    }
  },
  methods:{
    pageCurrentMethod(v){

      this.$axios.get('/Api/GetPostsList/'+v).then(value => {
        this.article=JSON.parse(value.data.data)

        // this.pageCurrentMax=
      })
    },

    goArticle(v) {
      this.$router.push({name:'blogView',params:{id:v}})
    }

  },
  created() {
    this.isMobile=(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
    const metas = document.getElementsByTagName("meta");
    metas[4].content='width=device-width,initial-scale=0.6'
    this.$axios.get('/Api/GetPostsList/1').then(value => {
      this.article=JSON.parse(value.data.data)
    })


  }
}
</script>

<style scoped>
.btnn{
  margin:25px 0 auto;
}

#detail-blog{
  box-sizing: border-box;
  box-shadow: 2px 5px 5px 10px rgba(0, 0, 0, 0.1);
  max-width: 960px;

  margin: 20px auto 0;
  border-radius: 19px;
  padding: 20px;
  background: #eee;
  border:1px dotted #aaa;
}
.posts_img{
  transition: all .4s;
}

.posts_img:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.cc{
  margin: 5px 0;
}

.readArticle{
  transition: all .4s
}

.post_name{

  transition: all .4s
}

.posts_body{
  transition: all .4s
}


.readArticle:hover{
  background-color: #0ea9f5;
  cursor: pointer;
  transform: scale(1.05);
}
.post_name:hover{
  color: #0ea9f5;
  cursor: pointer;
  transform: scale(1.05);
}
.posts_body:hover{
  color: #0ea9f5;
  cursor: pointer;
  transform: scale(1.05);
}

button{
  margin: 30px 20px 10px 20px;
}
@media screen and (min-width: 769px ) {
  .box {
    width: 100%;
    /*height: 100vh;*/
    background: url("~@/assets/privacy_bg.png");
  }




}

@media screen and (max-width: 768px ) {
  .box {
    width: 100%;
    /*height: 100vh;*/
    height: 100vh;
    background: url('~@/assets/bg_innerImg.jpg');
    background-size: 100% 100%;
  }

  /*.foot{*/
  /*  margin-top: 10vh;*/
  /*}*/

}
</style>
